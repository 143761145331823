import Header from "src/components/header/Header";
import styles from "./Homepage.module.css";
import CorporateImage from "src/images/corporate_image.png";
import React, { useEffect } from "react";
import Typed from "typed.js";
import { ReactComponent as BgPicture } from "src/images/background_image.svg";

const Homepage = () => {
  const el = React.useRef(null);

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: ["Website", "Portfolio", "Landing Page", " Design", "Consult"],
      typeSpeed: 100,
      loop: true,
    });

    return () => {
      typed.destroy();
    };
  }, []);
  return (
    <div>
      <Header />
      <div className={styles.container}>
        <div className={styles.bgPictureContainer}>
          <BgPicture className={styles.bgPicture} />
        </div>
        <div className={styles.middlecontainer}>
          <div className={styles.content}>
            <h1>
              Make <span style={{ color: "#d9241c" }} ref={el} /> For You
            </h1>
            <p>
              With a competitive advantage and innovativeness,{" "}
              <span style={{ color: "#d9241c" }}>Laman Hero </span> provides
              cutting-edge IT solutions all around the world.
            </p>
          </div>
          <div className={styles.corperateimagecontainer}>
            <img
              className={styles.corperateimage}
              src={CorporateImage}
              alt="corporate"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;

